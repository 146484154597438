import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

export function SingleBanner(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";

  const cssClass = utils.classNames(
    "cms_item",
    "single-banner",
    "banner-item",
    `bannerSize--${heightSet}`,
    item.cssClass || item.anchorName || ""
  );

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
  const bgUrl2 = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl2"));

  const captionTitle =
    cmsUtils.payload(item, "CaptionTitle2") ||
    cmsUtils.payload(item, "CaptionTitle");
  const linkUrl =
    cmsUtils.payload(item, "LinkUrl2") || cmsUtils.payload(item, "LinkUrl");
  const alignV = cmsUtils.payload(item, "VAlign") || "50%";

  const titlePanel = captionTitle ? (
    linkUrl ? (
      <SiteLink to={linkUrl} className='banner-item__link'>
        <div
          className='banner-item__title'
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
      </SiteLink>
    ) : (
      <div
        className='banner-item__title'
        dangerouslySetInnerHTML={{ __html: captionTitle }}
      ></div>
    )
  ) : null;

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      style={{
        backgroundImage:
          window.innerWidth > 576
            ? utils.css.bgUrlStyle(bgUrl)
            : bgUrl2 && bgUrl2 !== ""
            ? utils.css.bgUrlStyle(bgUrl2)
            : utils.css.bgUrlStyle(bgUrl),
        backgroundPositionY: alignV,
      }}
    >
      {titlePanel && <div className='banner_item__text'>{titlePanel}</div>}
      <div className='bottom-curve curve' style={{ display: "block" }}>
        <svg viewBox='0 0 500 50' preserveAspectRatio='none'>
          <path d='M0,80 L0,10 Q250,80 500,10 L500,80 Z' fill='#fff' />
        </svg>
      </div>
    </div>
  );
}
