import { fb, validators } from "../../../lib/form";

export function getContactModel(data) {
  const servicesTypeList = [
    { name: "Services Type*", value: "" },
    {
      name: "Life Insurance and TPD Insurance",
      value: "Life Insurance and TPD Insurance",
    },
    { name: "Trauma Insurance", value: "Trauma Insurance" },
    { name: "Income Protection", value: "Income Protection" },
    { name: "All", value: "All" },
    // {
    //   name: "Business Succession Planning",
    //   value: "Business Succession Planning",
    // },
    // { name: "Key Person Insurance", value: "Key Person Insurance" },
  ];

  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Name", type: "text" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
    servicesType: [
      data.servicesType || "",
      [validators.Required()],
      { label: null, type: "select", options: servicesTypeList },
    ],
    enquiry: [
      data.enquiry || "",
      [validators.Required()],
      { label: "Your Message", type: "textarea", style: { rows: 4 } },
    ],
  });

  return model;
}
