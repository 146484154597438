/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          <img css={style.logoImg} src='/assets/logos/logo.png' alt='logo' />
        </SiteLink>

        <a
          className='header__call__button'
          href='tel:0883638833'
          css={style.header__call__button}
        >
          <img src='/assets/phone-icon.png' alt='phone icon' />
        </a>

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />

        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
        {/* <div css={style.curve} className="top-curve curve">
          <svg viewBox="0 0 500 50" preserveAspectRatio="none">
            <path d="M0,0 L0,10 Q250,80 500,10 L500,0 Z" fill="rgba(255,255,255,0.9)" />
          </svg>
      </div> */}
      </header>
      {/* <div style={{width:'100%',height:'56px',position:'absolute'}}>
      
      </div> */}
    </Fragment>
  );
}
