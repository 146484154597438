import React from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";
import { useHistory } from "react-router-dom";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();
  const history = useHistory();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), emailReceiver: emailReceiver };
      post.send(env.apiBase + "/api/contact/send", value);
      setSendingStatus("pending");
    });
    console.log("submitting");
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);
    setTimeout(() => {
      history.push("/thank-you");
    }, 1000);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className='contactForm'>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='form-title'>General Enquiry</div>
        </div>
      </div>

      <div className='form-row' style={{ marginTop: "0.8rem" }}>
        <div className='col-md-6 mb-2'>{render("name")}</div>
        <div className='col-md-6 mb-2'>{render("phone")}</div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2'>{render("email")}</div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2'>{render("servicesType")}</div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2'>{render("enquiry")}</div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Send Message
            </Button>
            <ErrorMessage errors={post.errors} />
            {post.status === "done" && "Successfully sent!"}
          </div>
        </div>
      </div>
    </form>
  );
}
