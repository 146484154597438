import React, { useLayoutEffect, useRef } from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";
import BottomDrawer from "../../components/bottomDrawer";

export function BottomDock(props) {
  const links = [
    {
      url: "/",
      title: "HOME",
      icon: "home-white.png",
      iconHover: "home-teal.png",
    },
    {
      url: "/about-go-protect",
      title: "ABOUT US",
      icon: "about-us-white.png",
      iconHover: "about-us-teal.png",
    },
    {
      url: "/services",
      title: "SERVICES",
      icon: "services-white.png",
      iconHover: "services-teal.png",
    },
    {
      url: "/contact-us",
      title: "CONTACT US",
      icon: "contact-us-white.png",
      iconHover: "contact-us-teal.png",
    },
  ];

  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  const bottomMenu = useRef(null);
  useLayoutEffect(() => {
    const slider = new BottomDrawer(bottomMenu.current);
    setTimeout(() => slider.init(), 1000);
  }, []);

  return (
    <>
      <nav className='bottomMenu' ref={bottomMenu}>
        <div className='bottomMenu__bar'></div>
        <ul className='bottomMenu__links'>{linkElts}</ul>
      </nav>
      <div className='bottomMenuPadding'></div>
    </>
  );
}
