import { fb, validators } from "../../lib/form";
export function getModel(data) {
  const servicesTypeList = [
    { name: "---", value: "" },
    {
      name: "Life Insurance and TPD Insurance",
      value: "Life Insurance and TPD Insurance",
    },
    { name: "Trauma Insurance", value: "Trauma Insurance" },
    { name: "Income Protection", value: "Income Protection" },
    { name: "All", value: "All" },
    // {
    //   name: "Business Succession Planning",
    //   value: "Business Succession Planning",
    // },
    // { name: "Key Person Insurance", value: "Key Person Insurance" },
  ];

  const model = fb.group({
    firstName: [
      data.firstName || "",
      [validators.Required()],
      { label: null, type: "text" },
    ],
    lastName: [
      data.lastName || "",
      [validators.Required()],
      { label: null, type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: null, type: "email" },
    ],
    servicesType: [
      data.servicesType || "",
      [validators.Required()],
      { label: null, type: "select", options: servicesTypeList },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: null, type: "text" },
    ],
    postCode: [
      data.postCode || "",
      [validators.Required()],
      { label: null, type: "text" },
    ],
    //loanAmount: [data.loanAmount || '', [validators.Required()], { label:null, type: 'number' }]
  });
  return model;
}
